<template>
  <div class="RecordDetail">
    <el-card class="box-card">
      <el-page-header
        icon="el-icon-arrow-left"
        content="战绩详情"
        @back="goback"
      ></el-page-header>
      <div class="line"></div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
      >
        <el-table-column
          prop="seq"
          label="编号"
          type="index"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="game_name"
          label="游戏名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="round_id"
          label="场次ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="shop_name"
          label="所属店铺"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="shop_city"
          label="城市"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="player_name"
          label="昵称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="player_id"
          label="unionID"
          align="center"
        ></el-table-column>
        <el-table-column
          label="开始时间"
          prop="start_time"
          align="center"
        ></el-table-column>
        <el-table-column
          label="结束时间"
          prop="end_time"
          align="center"
        ></el-table-column>
        <el-table-column label="游戏时长" prop="duration" align="center">
        </el-table-column>
        <el-table-column label="结算状态" prop="settledOut" align="center">
          <template #default="scope">
            <span :class="scope.row.settled === 0 ? 'specialState' : ''">{{
              scope.row.settled === 0
                ? "未结算"
                : scope.row.settled === 1 || scope.row.settled === 2
                ? "结算"
                : ""
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import { getRecordDetail } from "../utils/api";
import timeFormat from "../utils/TimeFormat.js";
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import moment from "moment";
export default {
  setup() {
    const ctx = getCurrentInstance();
    const state = reactive({
      tableData: [],
      currentQueryPage: 0,
      pageSize: 20,
      totalItems: 0,
    });
    const getDetail = () => {
      const rid =
        ctx.appContext.config.globalProperties.$router.currentRoute.value.query
          .id;
      const shopId = sessionStorage.getItem("shopId");
      getRecordDetail(shopId, rid).then((res) => {
        console.log("res", res);
        state.totalItems = res.data.total;
        let resp = res.data.results;
        state.tableData = resp.map((round, index) => {
          return {
            index: index,
            ...round,
            start_time: moment(round.start_time).format("YYYY-MM-DD HH:mm:ss"),
            end_time: moment(round.end_time).format("YYYY-MM-DD HH:mm:ss"),
            duration: timeFormat.tsFormatTime(round.duration),
          };
        });
      });
    };
    const goback = () => {
      window.history.go(-1);
    };
    onMounted(() => {
      getDetail();
    });
    return {
      ...toRefs(state),
      goback,
    };
  },
};
</script>
<style lang="scss" scoped>
.RecordDetail {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  .line {
    width: 100%;
    height: 1px;
    background: #ebeef5;
    margin: 20px 0;
  }
}
</style>
